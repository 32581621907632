<template>
  <b-row>
    <b-col md="12">
      <payment-collection-filter :get-data="getDataList" />
    </b-col>
    <b-col md="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title>Ödeme Takvimi</b-card-title>
          <b-button
            v-if="!userData.id_customers"
            variant="primary"
            to="/payment-collections/customer-search"
          >
            <feather-icon icon="PlusIcon" />
            Oluştur
          </b-button>
        </b-card-header>
        <template v-if="dataList.length > 0">
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
          >
            <template #cell(customers)="data">
              <div>
                <small class="text-primary">{{ data.item.tnumber }}</small>
              </div>
              <template v-if="userData.id_customers !== data.item.id_customers">
                {{ data.item.customers }}
              </template>
              <div>
                <small class="text-muted">{{ data.item.title }}</small>
              </div>
            </template>
            <template #cell(payment_collection_status)="data">
              <div class="text-left">
                {{ data.item.payment_collection_status }}
                <template v-if="data.item.id_payment_collection_status !== '3'">
                  <div v-if="(data.item.alert1 || data.item.alert3 || data.item.alert5)">
                    <small class="text-success">
                      <feather-icon icon="BellIcon" />
                      Hatırlatma Açık
                    </small>
                  </div>
                  <div v-else>
                    <small class="text-danger">
                      <feather-icon icon="BellOffIcon" />
                      Hatırlatma Kapalı
                    </small>
                  </div>
                </template>
              </div>
            </template>
            <template #cell(amount)="data">
              <div class="text-right">
                <div v-if="data.item.partial_amount && data.item.amount !== data.item.partial_amount">
                  <del>
                    <div class="text-muted">
                      {{ data.item.amount | toCurrency }} TL
                    </div>
                  </del>
                  <div>
                    {{ data.item.amount - data.item.partial_amount | toCurrency }} TL
                  </div>
                  <div>
                    <small class="text-success">{{ data.item.partial_amount | toCurrency }} TL Ödendi</small>
                  </div>
                </div>
                <div v-else>
                  {{ data.item.amount | toCurrency }} TL
                </div>
                <div v-if="data.item.partial === '1'">
                  <small class="text-primary">Kısmi Ödeme Yapılabilir</small>
                </div>
              </div>
            </template>
            <template #cell(pdate)="data">
              <div class="text-left">
                <div class="text-warning">
                  {{ moment(data.item.pdate).format('DD.MM.YYYY') }}
                </div>
                <div>
                  <template v-if="moment(data.item.pdate).diff(moment().format('YYYY-MM-DD'),'days') >= 1">
                    <small class="text-muted">{{ moment(data.item.pdate).diff(moment().format('YYYY-MM-DD'),'days') }} gün kaldı.</small>
                  </template>
                  <template v-else-if="moment(data.item.pdate).diff(moment().format('YYYY-MM-DD'),'days') === 0">
                    <small class="text-muted">Bugün</small>
                  </template>
                  <template v-else>
                    <small class="text-muted">{{ Math.abs(moment(data.item.pdate).diff(moment().format('YYYY-MM-DD'),'days')) }} gün önce.</small>
                  </template>
                </div>
              </div>
            </template>
            <template #cell(control)="data">
              <b-button
                v-if="userData.id_customers"
                :disabled="data.item.id_payment_collection_status === '3' || data.item.id_payment_collection_status === '2'"
                variant="success"
                :to="'/payment/'+data.item.id"
              >
                <feather-icon icon="ShoppingCartIcon" />
                Ödeme Yap
              </b-button>
              <b-dropdown
                v-else
                text="İşlem"
                variant="outline-primary"
                size="sm"
                block
              >
                <b-dropdown-item :to="'/payment-collections/edit/' + data.item.id">
                  <feather-icon icon="EditIcon" />
                  Güncelle
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.id_payment_collection_status !== '3'"
                  @click="cancelPayment(data.item.id)"
                >
                  <feather-icon icon="XIcon" />
                  İptal Et
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.id_payment_collection_status === '3'"
                  @click="activatePayment(data.item.id)"
                >
                  <feather-icon icon="CheckIcon" />
                  Aktif Et
                </b-dropdown-item>
                <b-dropdown-item
                  @click="deletePayment(data.item.id)"
                >
                  <feather-icon icon="TrashIcon" />
                  Sil
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <b-card-footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="dataCount"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-card-footer>
        </template>
        <template v-else>
          <b-alert
            variant="info"
            show
            class="ml-2 mr-2"
          >
            <div class="alert-body text-center">
              <div>
                <FeatherIcon
                  icon="InfoIcon"
                  size="16"
                />
              </div>
              <p class="lead">
                Henüz bir kayıt bulunmuyor.
              </p>
            </div>
          </b-alert>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BCardFooter,
  BPagination,
  BButton,
  BCardHeader,
  BCardTitle,
  BAlert,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import PaymentCollectionFilter from '@/views/PaymentCollections/Components/filterCard.vue'

export default {
  name: 'PaymentCollections',
  components: {
    PaymentCollectionFilter,
    BDropdownItem,
    BDropdown,
    BAlert,
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BCardHeader,
    BCardTitle,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      baseurl: this.$store.state.app.baseURL,
      currentPage: 1,
      perPage: 25,
      search: '',
      fields: [
        {
          key: 'customers',
          label: 'MÜŞTERİ',
        },
        {
          key: 'pdate',
          label: 'Ödeme Günü',
          thClass: 'text-left text-nowrap width-100',
          tdClass: 'text-left text-nowrap width-100',
        },
        {
          key: 'amount',
          label: 'İŞLEM TUTARI',
          thClass: 'text-right text-nowrap width-100',
          tdClass: 'text-right text-nowrap width-100',
        },
        {
          key: 'payment_collection_status',
          label: 'DURUM',
          thClass: 'text-nowrap width-100',
          tdClass: 'text-nowrap width-100',
        },

        {
          key: 'control',
          label: '',
          thClass: 'text-right text-nowrap width-200',
          tdClass: 'text-right text-nowrap width-200',
        },
      ],
      dataQuery: {
        select: [
          'payment_collections.id AS id',
          'payment_collections.tnumber AS tnumber',
          'payment_collections.title AS title',
          'payment_collections.amount AS amount',
          'payment_collections.partial_amount AS partial_amount',
          'payment_collection_status.title AS payment_collection_status',
          'payment_collections.id_payment_collection_status AS id_payment_collection_status',
          'users.name AS username',
          'customers.company AS customers',
          'payment_collections.pdate AS pdate',
          'payment_collections.alert1 AS alert1',
          'payment_collections.alert3 AS alert3',
          'payment_collections.alert5 AS alert5',
          'payment_collections.partial AS partial',
          'payment_collections.id_customers AS id_customers',
        ],
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['paymentCollections/dataList']
    },
    dataCount() {
      return this.$store.getters['paymentCollections/dataCounts']
    },
    filterData() {
      return this.$store.getters['paymentCollections/filterData']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      const where = {}
      const like = {}
      if (this.filterData.customer) {
        like['customers.company'] = this.filterData.customer
      }
      if (this.filterData.sdate) {
        where['date(payment_collections.pdate) >='] = this.filterData.sdate
      }
      if (this.filterData.edate) {
        where['date(payment_collections.pdate) <='] = this.filterData.edate
      }
      if (this.filterData.id_payment_collection_status) {
        where['payment_collections.id_payment_collection_status'] = this.filterData.id_payment_collection_status
      }
      if (where) {
        this.dataQuery.where = where
      }
      if (like) {
        this.dataQuery.like = like
      }
      this.$store.dispatch('paymentCollections/getDataList', this.dataQuery)
    },
    activatePayment(id) {
      this.$swal({
        title: 'Ödemeyi Aktif Et',
        text: 'İptal edilen ödemeyi aktif etmek istiyor musunuz ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('paymentCollections/saveData', {
            id,
            id_payment_collection_status: 1,
          })
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: 'İşlem Başarılı',
                  text: 'Ödeme tekrardan aktif edildi.',
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              }
            })
        }
      })
    },
    cancelPayment(id) {
      this.$swal({
        title: this.$store.state.app.CancelTitle,
        text: this.$store.state.app.CancelMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('paymentCollections/saveData', {
            id,
            id_payment_collection_status: 3,
          })
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.CancelResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              }
            })
        }
      })
    },
    deletePayment(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('paymentCollections/removeData', id)
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              }
            })
        }
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
