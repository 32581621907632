<template>
  <div>
    <b-card title="Filtrele">
      <b-row>
        <b-col v-if="!userData.id_customers">
          <b-form-group
            label="Müşteri"
            label-for="company"
          >
            <b-form-input
              id="company"
              v-model="filterData.customer"
              placeholder="Müşteri Adı.."
              @keydown.enter="getData"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Başlangıç Tarihi"
            label-for="sdate"
          >
            <b-form-datepicker
              id="sdate"
              v-model="filterData.sdate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Bitiş Tarihi"
            label-for="edate"
          >
            <b-form-datepicker
              id="edate"
              v-model="filterData.edate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Durum"
            label-for="status"
          >
            <v-select
              id="status"
              v-model="filterData.id_payment_collection_status"
              :options="statuses"
              placeholder="Durum"
              label="title"
              :reduce="item => item.id"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="text-center"
        >
          <b-button
            variant="primary"
            @click="getData"
          >
            <feather-icon icon="FilterIcon" />
            Filtrele
          </b-button>
          <b-button
            v-if="filterData.customer ||filterData.sdate || filterData.sdate ||filterData.id_payment_collection_status"
            variant="warning"
            class="ml-1"
            @click="resetFilter"
          >
            <feather-icon icon="RefreshCwIcon" />
            Sıfırla
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton, BFormGroup, BFormInput, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'PaymentCollectionFilter',
  components: {
    vSelect,
    BFormDatepicker,
    BFormInput,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BButton,
  },
  props: {
    getData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    filterData() {
      return this.$store.getters['paymentCollections/filterData']
    },
    statuses() {
      return this.$store.getters['paymentCollectionStatus/dataList']
    },
  },

  created() {
    this.getStatuses()
  },
  methods: {
    getStatuses() {
      this.$store.dispatch('paymentCollectionStatus/getDataList')
    },
    resetFilter() {
      this.$store.commit('paymentCollections/RESET_FILTER_DATA')
      this.getData()
    },
  },
}
</script>
